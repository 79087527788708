import request from "@/utils/request";
export default {
  // 专属商品采购经理审核
  examine_status_aApi: (data) => {
    return request({
      url: "exclusive/examine_status_a",
      method: "post",
      data,
    });
  },
  // 行政部审核
  examine_status_bApi: (data) => {
    return request({
      url: "exclusive/examine_status_b",
      method: "post",
      data,
    });
  },
  // 仓管审核
  examine_status_cApi: (data) => {
    return request({
      url: "exclusive/examine_status_c",
      method: "post",
      data,
    });
  },
  // 财务部审核
  examine_status_dApi: (data) => {
    return request({
      url: "exclusive/examine_status_d",
      method: "post",
      data,
    });
  },
  // 总经办审核
  examine_status_eApi: (data) => {
    return request({
      url: "exclusive/examine_status_e",
      method: "post",
      data,
    });
  },
};
