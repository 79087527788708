import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "@/api";
import ElementUI from "element-ui";
import MakeHeader from "@/components/MakeHeader";
import MakeFooter from "@/components/MakeFooter";
import MakeBreadCrum from "@/components/MakeBreadCrumb";
import MakePriceFormat from "@/components/MakePriceFormat";
import MakePagination from "@/components/MakePagination";
import MakeBanner from "@/components/MakeBanner";
import Cache from "@/utils/cache";
import "element-ui/lib/theme-chalk/index.css";
import "nprogress/nprogress.css";
import "@/styles/base.scss";
import VueLazyload from "vue-lazyload";

Vue.prototype.$api = api;
Vue.prototype.$Cache = Cache;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueLazyload);

export const EventBus = new Vue(); // 创建事件总线

Vue.component("make-header", MakeHeader);
Vue.component("make-footer", MakeFooter);
Vue.component("make-breadcrumb", MakeBreadCrum);
Vue.component("make-priceformat", MakePriceFormat);
Vue.component("make-banner", MakeBanner);
Vue.component("make-pagination", MakePagination);
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
