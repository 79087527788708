import order from "./order";
import account from "./account";
import app from "./app";
import user from "./user";
import exclusive from "./goods";
export default {
  ...app,
  ...order,
  ...account,
  ...user,
  ...exclusive,
};
