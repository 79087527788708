<template>
  <span
    :style="{ color, 'font-weight': weight }"
    :class="(lineThrough ? 'line-through' : '') + ' price-format'"
  >
    <span><slot></slot></span>
    <span
      v-if="showSubscript"
      :style="{ 'font-size': subscriptSize + 'px', 'margin-right': '2px' }"
      >¥</span
    >
    <span :style="{ 'font-size': firstSize + 'px', 'margin-right': '1px' }">{{
      priceSlice.first
    }}</span>
    <span v-if="priceSlice.second" :style="{ 'font-size': secondSize + 'px' }"
      >.{{ priceSlice.second }}</span
    >
  </span>
</template>

<script>
export default {
  data() {
    return {
      priceSlice: {},
    };
  },
  components: {},
  props: {
    // 小数点前大小
    firstSize: {
      type: [String, Number],
      default: 20,
    },
    // 小数点后价格大小
    secondSize: {
      type: [String, Number],
      default: 20,
    },
    // 字体颜色
    color: {
      type: String,
      default: "#C91623",
    },
    // 字体加粗
    weight: {
      type: [String, Number],
      default: 500,
    },
    price: {
      type: [String, Number],
      default: "",
    },
    // ￥字体显示
    showSubscript: {
      type: Boolean,
      default: true,
    },
    // ￥字体大小
    subscriptSize: {
      type: [String, Number],
      default: 20,
    },
    lineThrough: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.priceFormat();
  },
  watch: {
    price(val) {
      this.priceFormat();
    },
  },
  methods: {
    priceFormat() {
      let { price } = this;
      let priceSlice = {};
      if (price !== null && price !== "" && price !== undefined) {
        price = parseFloat(price);
        price = String(price).split(".");
        priceSlice.first = price[0];
        priceSlice.second = price[1] || "00";
        this.priceSlice = priceSlice;
      } else {
        this.priceSlice = {
          first: 0,
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  line-height: 1;
}
</style>
