export const OrderRoute = [
  {
    path: "/cart",
    name: "Cart",
    meta: {
      title: "购物车",
    },
    component: () => import("@/views/order/cart"),
  },
  {
    path: "/settle",
    name: "Settle",
    meta: {
      title: "订单结算页",
      minor: true,
    },
    component: () => import("@/views/order/settle"),
  },
  {
    path: "/pay",
    name: "Pay",
    meta: {
      title: "订单支付页",
      minor: true,
    },
    component: () => import("@/views/order/pay"),
  },
  {
    path: "/logistics",
    name: "Logistics",
    meta: {
      title: "物流信息",
      minor: true,
    },
    component: () => import("@/views/order/logistics"),
  },
  {
    path: "/evaluation",
    name: "Evaluation",
    meta: {
      title: "商品评价",
      minor: true,
    },
    component: () => import("@/views/order/evaluation"),
  },
  {
    path: "/baishu",
    name: "Baishu",
    meta: {
      title: "审批信息",
      minor: true,
    },
    component: () => import("@/views/order/baishu"),
  },
];
